import React, { useEffect, useState } from 'react';
import '../styles/main.css';
import { AppPages } from '../utils/pages';
import numberWithCommas from '../utils/number';
import { useVocabTestState } from '../services/VocabTestProvider';
import { validateVocabTest } from '../services/vocabTest';
import { domToPng } from 'modern-screenshot';
import gliteLogo from '../assets/glite-logo.png';
import { submitShareImage } from '../services/l2Api';
import { isModernMobile } from '../utils/detectMobile';
import { useNavigate } from 'react-router-dom';

const SHARE_TEXT = 'Share your results';
const SHARE_LOADING_TEXT = 'Loading...';

interface VocabTestResultData {
  testId: string;
  name: string;
  level: string;
  levelDescription: string;
  testResultLemmaCount: number;
  testResultPhrasalVerbsCount: number;
  testResultIdiomsCount: number;
  nativeSpeakerLemmaCount: number;
  nativeSpeakerLemmasPercentage: number;
  nativeSpeakerIdiomsCount: number;
  nativeSpeakerIdiomsPercentage: number;
  nativeSpeakerPhrasalVerbsCount: number;
  nativeSpeakerPhrasalVerbsPercentage: number;
  emailProvided: boolean;
}

interface ProgressBarProps {
  title: string;
  userCount: number;
  nativeCount: number;
  description?: string;
  titleSize?: string;
}

function ProgressBar({
  title,
  userCount,
  nativeCount,
  description,
  titleSize = 'text-[14px]',
}: ProgressBarProps) {
  const safeUserCount = !isNaN(userCount) ? userCount : 0;
  const safeNativeCount = !isNaN(nativeCount) && nativeCount > 0 ? nativeCount : 1;

  const percentage = Math.min(Math.round((safeUserCount / safeNativeCount) * 100), 100);

  return (
    <div className="mb-[16px]">
      <div className="flex justify-between items-center mb-[6px]">
        <span className={`${titleSize} leading-[18px] font-bold`}>{title}</span>
        <div className="flex items-center gap-5">
          <div className="flex items-center gap-2">
            <div className="w-[10px] h-[10px] rounded-full bg-[#0A63F8]"></div>
            <span className="font-normal text-[14px] leading-[18px]">
              {numberWithCommas(userCount)}
            </span>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-[10px] h-[10px] rounded-full bg-[#dadce0]"></div>
            <span className="font-normal text-[14px] leading-[18px]">
              {numberWithCommas(nativeCount)}
            </span>
          </div>
        </div>
      </div>
      <div className="h-6 bg-[#dadce0] rounded-[4px]">
        <div
          className="h-full bg-[#0A63F8] rounded-[4px]"
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
      <div className={'text-start'}>
        {description && (
          <p className="text-[14px] leading-[18px] mt-[15px]">{description}</p>
        )}
      </div>
    </div>
  );
}

export default function VocabTestResultsScreen() {
  const [vocabTestResultData, setVocabTestResultData] = useState<VocabTestResultData>();
  const [shareImageUrl, setShareImageUrl] = useState<string | null>(null);
  const [imageBlob, setImageBlob] = useState<Blob | null>(null);
  const [dataUrl, setDataUrl] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const { vocabTest, setVocabTest } = useVocabTestState();
  const navigate = useNavigate();

  useEffect(() => {
    if (!vocabTest) {
      console.error('No vocab test data found');
    } else if (!validateVocabTest(vocabTest)) {
      console.error('Invalid vocab test data');
    }

    if (!vocabTest || !validateVocabTest(vocabTest)) {
      navigate(AppPages.Home.url);
      return;
    }

    setVocabTestResultData({
      testId: vocabTest!.test_id,
      name: vocabTest!.user_profile!.full_name || '',
      level: vocabTest!.vocab_size!.level,
      levelDescription: vocabTest!.vocab_size!.level_description,
      testResultLemmaCount: vocabTest!.vocab_size!.lemmas_number,
      testResultPhrasalVerbsCount: vocabTest!.vocab_size!.phrasal_verbs_number,
      testResultIdiomsCount: vocabTest!.vocab_size!.idioms_number,
      nativeSpeakerLemmaCount: vocabTest!.vocab_size!.native_speaker_lemmas,
      nativeSpeakerLemmasPercentage:
        vocabTest!.vocab_size!.native_speaker_lemmas_percentage,
      nativeSpeakerIdiomsCount: vocabTest!.vocab_size!.native_speaker_idioms,
      nativeSpeakerIdiomsPercentage:
        vocabTest!.vocab_size!.native_speaker_idioms_percentage,
      nativeSpeakerPhrasalVerbsCount: vocabTest!.vocab_size!.native_speaker_phrasal_verbs,
      nativeSpeakerPhrasalVerbsPercentage:
        vocabTest!.vocab_size!.native_speaker_phrasal_verbs_percentage,
      emailProvided: !!vocabTest!.user_profile!.email,
    });

    setVocabTest(null);
  }, []);

  async function generateImage() {
    // If we already have an image, return it
    if (dataUrl && (imageBlob || shareImageUrl)) {
      return { dataUrl, imageBlob, shareImageUrl, success: true };
    }

    setIsProcessing(true);

    // Create a hidden clone for image generation
    const hiddenContainer = document.createElement('div');
    hiddenContainer.style.position = 'absolute';
    hiddenContainer.style.left = '-9999px';
    hiddenContainer.style.top = '-9999px';
    document.body.appendChild(hiddenContainer);

    // Clone the content
    const originalContainer = document.getElementById('testResults');
    if (!originalContainer) {
      console.error('Results container not found');
      setIsProcessing(false);
      document.body.removeChild(hiddenContainer);
      return { success: false };
    }

    try {
      const clonedContainer = originalContainer.cloneNode(true) as HTMLElement;
      hiddenContainer.appendChild(clonedContainer);

      clonedContainer.classList.add('invert-gradient', 'flex', 'flex-shrink');
      clonedContainer.style.padding = '20px';
      clonedContainer.style.height = 'min-content';
      clonedContainer.style.width = '353px';

      const downloadOnlyElements = clonedContainer.querySelectorAll('.download-only');
      downloadOnlyElements.forEach((element) => {
        (element as HTMLElement).classList.remove('hidden');
      });

      const noDownloadElements = clonedContainer.querySelectorAll('.no-download');
      noDownloadElements.forEach((element) => {
        (element as HTMLElement).style.display = 'none';
      });

      const url = await domToPng(clonedContainer, {
        width: 353,
        height: 1000,
        quality: 1,
        scale: 3,
      });

      document.body.removeChild(hiddenContainer);

      const response = await fetch(url);
      const blob = await response.blob();
      setDataUrl(url);
      setImageBlob(blob);

      let serverImageUrl = null;
      try {
        const imageUrl = await submitShareImage(vocabTestResultData!.testId, blob);
        setShareImageUrl(imageUrl);
        serverImageUrl = imageUrl;
        console.log('Image shared successfully:', imageUrl);
      } catch (error) {
        console.error('Failed to share image with server:', error);
      }

      setIsProcessing(false);
      return {
        dataUrl: url,
        imageBlob: blob,
        shareImageUrl: serverImageUrl,
        success: true,
      };
    } catch (error) {
      console.error('Error generating image:', error);
      // Ensure cleanup
      if (document.body.contains(hiddenContainer)) {
        document.body.removeChild(hiddenContainer);
      }
      setIsProcessing(false);
      return { success: false };
    }
  }

  async function downloadResults() {
    if (isProcessing) return;

    const result = await generateImage();
    if (!result.success) {
      console.error('Failed to generate image');
      return;
    }

    let file: File | null = null;
    try {
      if (navigator?.share && navigator.canShare) {
        const shareData: ShareData = {
          title: 'Glite Vocabulary Test Results',
          text: 'Check out my vocabulary test results!',
          url: 'https://test.glite.ai/',
        };

        if (result.imageBlob) {
          file = new File([result.imageBlob], 'glite-vocab-results.png', {
            type: 'image/png',
          });
          if (navigator.canShare && navigator.canShare({ files: [file] })) {
            shareData.files = [file];
          }
        }

        navigator.share(shareData).catch((err) => console.error('Error sharing:', err));
      }

      if (
        result.dataUrl &&
        (!isModernMobile() || (file !== null && !navigator.canShare({ files: [file] })))
      ) {
        const link = document.createElement('a');
        link.download = 'glite-vocab-results.png';
        link.href = result.dataUrl;
        link.click();
      }
    } catch (error) {
      console.error('Error sharing or downloading results:', error);
    }
  }

  if (!vocabTestResultData) return null;

  return (
    <main>
      <div className="container">
        <h1 className="title-lg">Your results</h1>
        <div className="inner-container text-container" id="testResults">
          <span className="no-download">
            Thank you for completing the
            <br />
            vocabulary test!
          </span>
          <button
            onClick={downloadResults}
            className="btn mt-2 mb-2 no-download"
            disabled={isProcessing}
          >
            {isProcessing ? SHARE_LOADING_TEXT : SHARE_TEXT}
          </button>
          <div className="flex items-center justify-center mb-2 hidden download-only">
            <img src={gliteLogo} alt="Glite logo" className="w-[120px] h-[120px]" />
          </div>
          <div className="title-2 mb-2 hidden download-only">
            {vocabTestResultData.name ? vocabTestResultData.name : 'Glite Vocabulary'}
          </div>
          <div className="inner-container gap-6 mt-1">
            <div className="flex flex-col gap-3 px-4 py-5 rounded-[8px] bg-white shadow-lg border text-center">
              <h4 className="mb-1 text-[14px] leading-[18px] font-bold text-[#111111]">
                Level of proficiency
              </h4>
              <div className="text-[34px] leading-[42px] font-bold text-[#111111]">
                {vocabTestResultData.level}
              </div>
              <div className="text-[17px] leading-[22px] font-bold text-[#111111]">
                {vocabTestResultData.levelDescription}
              </div>
              <div className="text-[14px] leading-[18px] text-black/90 flex flex-col gap-2">
                <p>
                  The CEFR system has six levels: A1 and A2 (beginner), B1 and B2
                  (intermediate), and C1 and C2 (advanced).
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-3 px-4 py-5 rounded-[8px] bg-white shadow-lg border text-center">
              <h4 className="mb-1 text-[14px] leading-[18px] font-bold text-[#111111]">
                Vocabulary size
              </h4>
              <div className="flex items-center justify-center gap-3 mb-[16px]">
                <div className="flex items-center gap-2">
                  <div className="w-[10px] h-[10px] rounded-full bg-[#0A63F8]"></div>
                  <span className="text-[14px] leading-[18px] font-normal">You</span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-[10px] h-[10px] rounded-full bg-[#dadce0]"></div>
                  <span className="text-[14px] leading-[18px] font-normal">
                    Native speaker
                  </span>
                </div>
              </div>
              <ProgressBar
                title="Words"
                userCount={vocabTestResultData.testResultLemmaCount}
                nativeCount={vocabTestResultData.nativeSpeakerLemmaCount}
                description='We count words as lemmas, the base form of a word. For example, "break" is the lemma for "breaks," "broke," "broken," and "breaking."'
              />
              <ProgressBar
                title="Phrasal verbs"
                userCount={vocabTestResultData.testResultPhrasalVerbsCount || 0}
                nativeCount={vocabTestResultData.nativeSpeakerPhrasalVerbsCount}
                titleSize="text-[14px]"
              />
              <ProgressBar
                title="Idioms"
                userCount={vocabTestResultData.testResultIdiomsCount || 0}
                nativeCount={vocabTestResultData.nativeSpeakerIdiomsCount}
                titleSize="text-[14px]"
              />
            </div>
          </div>
          <p className="text-gray-600 mt-2 text-sm hidden download-only">glite.ai</p>
          {vocabTestResultData.emailProvided && (
            <p className="px-3 mt-4 no-download">
              You will receive an email with your detailed results within minutes.
            </p>
          )}
          <div className="flex flex-col justify-center items-center gap-6 mt-4 no-download">
            <button onClick={downloadResults} className="btn" disabled={isProcessing}>
              {isProcessing ? SHARE_LOADING_TEXT : SHARE_TEXT}
            </button>
            <a href="https://glite.ai/" className="btn light">
              Skip Sharing
            </a>
          </div>
        </div>
      </div>
    </main>
  );
}

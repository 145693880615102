import React from 'react';
import { useCombobox } from 'downshift';
import {
  Label,
  SelectIcon,
  InputGroup,
  InputHelpAndErrors,
  InputWrapper,
} from './utils/forms';
import { FieldError } from 'react-hook-form';
import useDropdownPosition from './utils/dropdown';

interface ComboboxFieldProps {
  label: string;
  name: string;
  value: string;
  onChange: (value: string) => void;
  options: Record<string, string>;
  autoComplete?: string;
  placeholder?: string;
  required?: boolean;
  errors?: FieldError;
}

const ComboboxField = React.forwardRef<HTMLInputElement, ComboboxFieldProps>(
  (
    {
      label,
      name,
      value = '',
      onChange,
      options,
      autoComplete = 'off',
      errors,
      placeholder = 'Select an option',
      required = false,
    },
    ref,
  ) => {
    const { dropdownUp, wrapperRef } = useDropdownPosition();

    const selectValues = Object.values(options);

    const filteredItems = selectValues.filter(
      (item) => !value || item.toLowerCase().includes(value.trim().toLowerCase()),
    );

    const { isOpen, getMenuProps, getInputProps, getItemProps, highlightedIndex, reset } =
      useCombobox({
        items: filteredItems,
        inputValue: value,
        onInputValueChange: ({ inputValue }) => {
          onChange(inputValue || ''); // Update react-hook-form on input change
        },
        onSelectedItemChange: ({ selectedItem }) => {
          onChange(selectedItem || ''); // Update react-hook-form on selected item change
        },
        stateReducer: (_state, actionAndChanges) => {
          const { changes, type } = actionAndChanges;
          switch (type) {
            case useCombobox.stateChangeTypes.InputBlur:
              return {
                ...changes,
                inputValue: value,
              };
            default:
              return changes;
          }
        },
      });

    return (
      <InputGroup errors={errors}>
        <Label label={label} htmlFor={name} required={required} />

        <InputWrapper ref={wrapperRef}>
          <SelectIcon
            selected={!!value}
            onClick={(event) => {
              event.stopPropagation();
              onChange(''); // Clear the input
              reset();
            }}
          />
          <input
            className={`input-field select ${isOpen ? 'open' : ''} ${dropdownUp ? 'above' : 'below'}`}
            placeholder={placeholder}
            required={required}
            aria-invalid={errors ? 'true' : 'false'}
            {...getInputProps({ ref })}
            autoComplete={autoComplete}
          />
          <ul
            className={`select-option-list ${isOpen ? 'open' : ''} ${dropdownUp ? 'above' : 'below'}`}
            {...getMenuProps()}
          >
            {isOpen &&
              filteredItems.map((item, index) => (
                <li
                  key={item}
                  className={`select-option ${
                    highlightedIndex === index ? 'highlighted' : ''
                  }`}
                  {...getItemProps({ index, item })}
                >
                  {item}
                </li>
              ))}

            {isOpen && !filteredItems.length && (
              <li className="select-option no-results">Invalid selection.</li>
            )}
          </ul>
        </InputWrapper>

        <InputHelpAndErrors errors={errors} />
      </InputGroup>
    );
  },
);

ComboboxField.displayName = 'ComboboxField';
export default ComboboxField;

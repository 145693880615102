export interface Page {
  name: string;
  url: string;
  sectionNumber?: number;
}

type AppPages = {
  [key: string]: Page;
};

export const AppPages: AppPages = {
  Home: { name: 'Home', url: '/' },
  VocabStart: { name: 'VocabStart', url: '/vocab/start' },
  VocabCEFR: { name: 'VocabCEFR', url: '/vocab/cefr' },
  VocabLevel: { name: 'VocabLevel', url: '/vocab/level' },
  VocabAboutTest: { name: 'VocabAboutTest', url: '/vocab/about' },
  VocabFakeWords: { name: 'VocabFakeWords', url: '/vocab/fake' },
  VocabWords: { name: 'VocabWords', url: '/vocab/words' },
  VocabEmail: { name: 'VocabEmail', url: '/vocab/email' },
  VocabResults: { name: 'VocabResults', url: '/vocab/results' },
  VocabError: { name: 'VocabError', url: '/vocab/error' },
};

export function getPageIndex(pageName: string): number {
  const page = Object.values(AppPages).find((page) => page.name === pageName);
  if (!page) {
    throw new Error(`Page not found: ${pageName}`);
  }
  return Object.values(AppPages).indexOf(page);
}

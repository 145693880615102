import { UserProfile, VocabTest, VocabTestStatus } from '../utils/models';
import { getUserProfileFromLocalStorage } from './userProfile';
import { useNavigate } from 'react-router-dom';
import { AppPages, Page, getPageIndex } from '../utils/pages';
import { useEffect } from 'react';

export const VOCAB_TEST_VERSION = 3;

export function validateVocabTest(vocabTest: VocabTest | null) {
  if (!vocabTest) {
    return false;
  }

  const allWordsChecked = () => {
    for (const word of vocabTest.words) {
      if (word.checked === null) {
        return false;
      }
    }
    return true;
  };

  return (
    vocabTest &&
    vocabTest.words &&
    allWordsChecked() &&
    vocabTest.user_profile &&
    vocabTest.status === VocabTestStatus.COMPLETED &&
    vocabTest.vocab_size &&
    vocabTest.vocab_size.lemmas_number !== null &&
    vocabTest.vocab_size.level &&
    vocabTest.test_version === VOCAB_TEST_VERSION
  );
}

/** Determines progress of a vocab test and return the appropriate stage for the user to be on */
function getVocabTestRequiredStage(
  vocabTest?: VocabTest | null,
  userProfile?: UserProfile,
): Page {
  console.debug('getVocabTestProgress', { vocabTest, userProfile });
  if (!userProfile || !userProfile.age_group || !userProfile.native_language) {
    return AppPages.VocabStart;
  } else if (typeof userProfile.cefr_test_2_years === 'undefined') {
    return AppPages.VocabCEFR;
  }

  if (!vocabTest || !vocabTest.words) {
    return AppPages.VocabFakeWords;
  }

  const numberWordsChecked = vocabTest.words.filter(
    (word) => word.checked !== null,
  ).length;
  if (numberWordsChecked < vocabTest.words.length) {
    return AppPages.VocabWords;
  }

  if (!userProfile.country_of_residence) {
    return AppPages.VocabEmail;
  }

  return AppPages.VocabResults;
}

export function useVocabStage(
  currentStage: Page,
  userProfile?: UserProfile,
  vocabTest?: VocabTest | null,
) {
  const navigate = useNavigate();

  if (!userProfile) {
    userProfile = getUserProfileFromLocalStorage();
  }

  const requiredStage = getVocabTestRequiredStage(vocabTest, userProfile);
  const maximumStageIndexForData = getPageIndex(requiredStage.name);
  const currentStageIndex = getPageIndex(currentStage.name);

  console.debug('useVocabStage', {
    currentStage,
    requiredStage,
    currentStageIndex,
    maximumStageIndexForData,
  });

  useEffect(() => {
    if (currentStageIndex > maximumStageIndexForData) {
      return navigate(requiredStage.url);
    }
  }, [currentStageIndex, maximumStageIndexForData, navigate]);
}

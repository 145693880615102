import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/main.css';
import { GliteButton } from '../components/GliteButton';
import GliteLogo from '../assets/glite-logo.png';
import { AppPages } from '../utils/pages';

export default function HomePage() {
  const navigate = useNavigate();

  return (
    <main>
      <div className="container">
        <div className="mt-auto"></div>
        <img src={GliteLogo} alt="Glite Logo" className="logo" width={90} height={90} />
        <h1 className="title-lg">Vocabulary Test</h1>

        <div className="inner-container my-2">
          <p>Test your knowledge of English words, phrasal verbs, and idioms.</p>

          <p>Compare your results with other learners.</p>

          <p>Get personalized feedback to improve your vocabulary.</p>
        </div>

        <GliteButton onClick={() => navigate(AppPages.VocabStart.url)}>
          Start the test
        </GliteButton>

        <span className="title-4 mt-2">~5 minutes</span>

        <div className="mt-auto mb-4 text-gray-700 flex flex-row gap-4">
          <a href="https://glite.ai" target="_blank" className="link" rel="noreferrer">
            About Glite
          </a>
          <span>|</span>
          <a
            href="https://glite.ai/policies/terms-and-conditions"
            target="_blank"
            className="link"
            rel="noreferrer"
          >
            Terms
          </a>
          <span>|</span>
          <a
            href="https://glite.ai/policies/privacy"
            target="_blank"
            className="link"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </main>
  );
}

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/main.css';
import { GliteButton } from '../components/GliteButton';
import { AppPages } from '../utils/pages';
import { useVocabTestState } from '../services/VocabTestProvider';
import { getUserProfileFromLocalStorage } from '../services/userProfile';
import { useVocabStage } from '../services/vocabTest';

export default function VocabFakeWordsScreen() {
  const { vocabTest } = useVocabTestState();
  const navigate = useNavigate();
  const userProfile = getUserProfileFromLocalStorage();
  const [loading, setLoading] = React.useState(false);

  useVocabStage(AppPages.VocabFakeWords, userProfile);

  useEffect(() => {
    if (loading && vocabTest && vocabTest.words) {
      return navigate(AppPages.VocabWords.url);
    }
  }, [vocabTest, loading]);

  return (
    <main>
      <div className="container">
        <div className="inner-container text-container">
          <h1 className="title-lg mb-2">Be careful! Fake words</h1>

          <p>Some entries aren&apos;t real English words, phrasal verbs, or idioms.</p>

          <p>These challenges help us assess your knowledge more accurately.</p>

          <p>Don&apos;t worry if you face some you don&apos;t recognize!</p>
        </div>

        <GliteButton
          className="mt-2"
          disabled={loading}
          onClick={() => {
            setLoading(true);
          }}
        >
          {loading ? 'Loading...' : 'Start the test'}
        </GliteButton>
      </div>
    </main>
  );
}

import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getUserProfileFromLocalStorage } from '../services/userProfile';
import { submitVocabTestResults } from '../services/l2Api';
import { AnimatePresence, motion } from 'framer-motion';
import { VocabTestContext } from '../VocabRoutes';
import { VocabTestStatus } from '../utils/models';
import { GliteMotionButton } from '../components/GliteButton';
import { AppPages } from '../utils/pages';
import { useVocabStage } from '../services/vocabTest';
import { useHotkeys } from 'react-hotkeys-hook';
import CheckIcon from '../assets/check-icon.png';
import CrossIcon from '../assets/cross-icon.png';
import LetterFIcon from '../assets/letter-f.png';
import LetterJIcon from '../assets/letter-j.png';

const FAKE_NOTICE_DURATION = 3000;
const HOTKEY_BUTTON_ANIMATION_DURATION = 100;

export default function VocabTestScreen() {
  const navigate = useNavigate();
  const { index } = useParams();
  const { vocabTest, setVocabTest } = useContext(VocabTestContext)!;
  const [showMeaning, setShowMeaning] = useState<boolean>(false);
  const [wordIndexChangeTime, setWordIndexChangeTime] = useState<Date | undefined>();
  const [direction, setDirection] = useState('forward');
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [fakeCardEndTimer, setFakeCardEndTimer] = useState<NodeJS.Timeout | null>(null);
  const userProfile = getUserProfileFromLocalStorage();
  const [hasSeenFakeNotice, setHasSeenFakeNotice] = useState<boolean>(false);
  const [showFakeNotification, setShowFakeNotification] = useState<string | null>(null);
  const [isFakeNotificationPositive, setIsFakeNotificationPositive] =
    useState<boolean>(true);
  const [showHotkeysPopover, setShowHotkeysPopover] = useState<boolean>(false);

  useVocabStage(AppPages.VocabWords, userProfile, vocabTest);

  const wordIndex = () => {
    if (index && vocabTest && vocabTest.words) {
      const wordIndex = parseInt(index);
      if (wordIndex < 0) {
        return 0;
      } else if (wordIndex >= vocabTest.words.length) {
        return vocabTest.words.length - 1;
      }
      return wordIndex;
    }
    return 0;
  };

  if (!vocabTest || !vocabTest.words) return;

  const pagesInTest = vocabTest!.words.length;
  const pagesRemainingInTest = pagesInTest - wordIndex();
  const isPreviousWordChecked =
    wordIndex() > 0 ? vocabTest!.words[wordIndex() - 1].checked! || false : false;
  const isPreviousWordFake =
    wordIndex() > 0 ? vocabTest!.words[wordIndex() - 1].fake! || false : false;

  useEffect(() => {
    setWordIndexChangeTime(new Date());
    if (isPreviousWordFake && direction === 'forward') {
      setShowFakeNotification(previousSubject);
      setIsFakeNotificationPositive(!isPreviousWordChecked);
      setHasSeenFakeNotice(true);
      if (fakeCardEndTimer) {
        clearTimeout(fakeCardEndTimer);
      }
      setFakeCardEndTimer(
        setTimeout(() => {
          setShowFakeNotification(null);
        }, FAKE_NOTICE_DURATION),
      );
    }
    if (wordIndex() > 1 && wordIndex() % 10 === 0) {
      submitVocabTestResults(vocabTest!).then(() => {});
    }
  }, [wordIndex()]);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const goBackOneWord = () => {
    if (wordIndex() > 0) {
      setShowMeaning(false);
      setShowFakeNotification(null);

      navigate(AppPages.VocabWords.url + `/${wordIndex() - 1}`);
    }
  };

  const handleTestChoiceMade = (knownStatus: string) => {
    const nextWordIndex = wordIndex() + 1;
    const isKnown = knownStatus === 'known';
    setVocabTest((oldTest) => {
      const newTest = { ...oldTest! };
      newTest.words[wordIndex()].checked = isKnown;
      newTest.words[wordIndex()].revealed_meaning = showMeaning;
      newTest.status = VocabTestStatus.IN_PROGRESS;
      if (wordIndexChangeTime) {
        newTest.words[wordIndex()].decision_time =
          new Date().getTime() - wordIndexChangeTime.getTime();
      }
      return newTest;
    });
    setDirection('forward');
    setShowMeaning(false);
    setShowFakeNotification(null);
    if (nextWordIndex >= pagesInTest) {
      submitVocabTestResults(vocabTest!).then(() => {});
      navigate(AppPages.VocabEmail.url);
    } else {
      navigate(AppPages.VocabWords.url + `/${nextWordIndex}`);
    }
  };

  useHotkeys('J', () => {
    const knownButton = document.getElementById('knownButton');
    if (knownButton) {
      knownButton.style.transform = 'scale(0.9)';
      knownButton.style.transition = 'transform 0.1s';
      setTimeout(() => {
        knownButton.style.transform = 'scale(1)';
      }, HOTKEY_BUTTON_ANIMATION_DURATION);
    }
    handleTestChoiceMade('known');
  });

  useHotkeys('F', () => {
    const unknownButton = document.getElementById('unknownButton');
    if (unknownButton) {
      unknownButton.style.transform = 'scale(0.9)';
      unknownButton.style.transition = 'transform 0.1s';
      setTimeout(() => {
        unknownButton.style.transform = 'scale(1)';
      }, HOTKEY_BUTTON_ANIMATION_DURATION);
    }
    handleTestChoiceMade('unknown');
  });

  useHotkeys('H', () => {
    setShowMeaning(true);
  });

  useHotkeys(['ArrowLeft', 'Backspace'], () => {
    setDirection('backward');
    goBackOneWord();
  });

  useHotkeys('Escape', () => {
    setShowHotkeysPopover(!showHotkeysPopover);
  });

  const previousSubject =
    wordIndex() > 0
      ? vocabTest!.words[wordIndex() - 1].sentence!.split('**')[1].split('**')[0]
      : '';
  const currentSubject = vocabTest!.words[wordIndex()]
    .sentence!.split('**')[1]
    .split('**')[0];
  const currentSentenceHighlighted = vocabTest!.words[wordIndex()]
    .sentence!.split(/(\*\*.*?\*\*)/g)
    .map((part, i) => {
      if (part.startsWith('**') && part.endsWith('**')) {
        return (
          <span key={i} className="text-[#0A63F8]">
            {part.slice(2, -2)}
          </span>
        );
      }
      return part;
    });

  const forwardAnimationVariants = {
    initial: { opacity: 0, x: 150 },
    in: { opacity: 1, x: 0 },
    out: { opacity: 0, x: -150 },
  };

  const backwardAnimationVariants = {
    initial: { opacity: 0, x: -150 },
    in: { opacity: 1, x: 0 },
    out: { opacity: 0, x: 150 },
  };

  const fakeWordNotificationVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -40 },
  };

  const popoverVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.95 },
  };

  const animationVariants =
    direction === 'forward' ? forwardAnimationVariants : backwardAnimationVariants;
  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.3,
  };

  return (
    <main>
      <div className="container h-full md:max-h-[650px] justify-between">
        <div className="flex flex-col w-full gap-[4px]">
          <div className="flex justify-between items-center w-full min-h-[25px] relative">
            <div
              onClick={() => {
                setDirection('backward');
                goBackOneWord();
              }}
              className={`flex items-center -mx-1.5 cursor-pointer ${wordIndex() > 0 ? 'inline' : 'opacity-0'}`}
              onMouseEnter={() => {
                setDirection('backward');
              }}
            >
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="#11111199"
                  d="M9 13C9 12.855 9.02788 12.7211 9.08363 12.5983C9.13939 12.4756 9.22581 12.3556 9.34289 12.2385L14.3525 7.27615C14.5364 7.09205 14.7595 7 15.0215 7C15.2947 7 15.5261 7.09205 15.7157 7.27615C15.9052 7.46583 16 7.69456 16 7.96234C16 8.23013 15.8969 8.46722 15.6906 8.67364L11.2999 13L15.6906 17.3264C15.8969 17.5328 16 17.7699 16 18.0377C16 18.3054 15.9052 18.5314 15.7157 18.7155C15.5261 18.9052 15.2947 19 15.0215 19C14.7595 19 14.5364 18.9079 14.3524 18.7238L9.34289 13.7615C9.1143 13.5439 9 13.2901 9 13Z"
                />
              </svg>
              <span className="block title-5 text-black/60 leading-none">Back</span>
            </div>
            <span className="title-5 text-black/60 leading-none">
              {pagesRemainingInTest} pages left
            </span>
          </div>
          <div className="progress-bar">
            <div
              className="progress-bar-fill"
              style={{
                width: `${(wordIndex() / pagesInTest) * 100}%`,
              }}
            ></div>
          </div>
        </div>
        <div
          className="flex-grow flex items-center justify-center relative"
          onClick={() => {
            setShowMeaning(true);
          }}
        >
          <AnimatePresence>
            {showFakeNotification && (
              <motion.div
                className="bg-black/5 p-4 rounded-lg text-center mb-2 w-full absolute top-2 left-0 right-0 z-10"
                key="notification"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={fakeWordNotificationVariants}
                transition={{ duration: 0.3 }}
              >
                <div className="flex flex-col justify-center items-center gap-2">
                  {isFakeNotificationPositive ? (
                    <img
                      src={CheckIcon}
                      alt="Check icon"
                      className="w-8 h-8 inline-block"
                    />
                  ) : (
                    <img
                      src={CrossIcon}
                      alt="Cross icon"
                      className="w-8 h-8 inline-block"
                    />
                  )}
                  <div>
                    <span className="capitalize font-bold text-[#0A63F8]">
                      {showFakeNotification}
                    </span>{' '}
                    was fake
                  </div>
                  {!isFakeNotificationPositive && !hasSeenFakeNotice && (
                    <span className="text-[14px] leading-[18px]">
                      We added some made-up words to better assess your English knowledge.
                    </span>
                  )}
                </div>
              </motion.div>
            )}
          </AnimatePresence>

          <AnimatePresence>
            {showHotkeysPopover && (
              <motion.div
                className="bg-white p-4 rounded-lg shadow-lg absolute z-20 inset-x-0 mx-auto w-[300px] md:w-[390px]"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={popoverVariants}
                transition={{ duration: 0.2 }}
              >
                <div className="flex justify-between items-center mb-3">
                  <h3 className="font-bold text-lg">Keyboard Shortcuts</h3>
                </div>

                <div className="space-y-2 mt-5">
                  <div className="flex justify-between">
                    <span className="font-medium">J</span>
                    <span>Mark as Known</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="font-medium">F</span>
                    <span>Mark as Not sure</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="font-medium">H</span>
                    <span>Reveal meaning</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="font-medium">← / Backspace</span>
                    <span>Go back</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="font-medium">Esc</span>
                    <span>Toggle this help</span>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>

          <AnimatePresence mode="popLayout">
            <motion.div
              className="flex flex-col items-center w-full h-full"
              initial="initial"
              animate="in"
              exit="out"
              variants={animationVariants}
              key={wordIndex()}
              transition={pageTransition}
            >
              <div className="flex flex-col items-center h-full justify-center">
                <div className="text-center mt-20 font-bold text-[34px] leading-[42px]">
                  {currentSentenceHighlighted}
                </div>
                <div className="h-[60px] mt-5 flex items-center">
                  {showMeaning ? (
                    <div className="font-normal text-[17px] leading-[22px]">
                      <span>
                        {currentSubject.charAt(0).toUpperCase() + currentSubject.slice(1)}
                        :{' '}
                      </span>
                      <span>{vocabTest!.words[wordIndex()].definition}</span>
                    </div>
                  ) : (
                    <div className="font-normal text-[14px] leading-[18px] hover:text-black/80 cursor-pointer">
                      <span className="border-b border-dotted border-current pb-[1px]">
                        Show meaning
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
        </div>

        <div className="flex justify-between md:justify-center gap-[18px] w-full">
          {['unknown', 'known'].map((knownStatus) => {
            return (
              <GliteMotionButton
                id={`${knownStatus}Button`}
                key={knownStatus}
                className="w-[168px]"
                whileTap={{ scale: 0.9 }}
                onMouseEnter={() => {
                  setDirection('forward');
                }}
                onClick={() => {
                  handleTestChoiceMade(knownStatus);
                }}
              >
                {knownStatus === 'known' ? (
                  <div className={'flex items-center justify-center gap-3'}>
                    <img
                      src={LetterJIcon}
                      width={'24px'}
                      height={'24px'}
                      hidden={isMobile}
                    />
                    Know
                  </div>
                ) : (
                  <div className={'flex items-center justify-center gap-3'}>
                    <img
                      src={LetterFIcon}
                      width={'24px'}
                      height={'24px'}
                      hidden={isMobile}
                    />
                    Not sure
                  </div>
                )}
              </GliteMotionButton>
            );
          })}
        </div>

        {!isMobile && (
          <div className="mt-2 text-center">
            <button
              className="text-black/40 text-xs hover:text-black/60"
              onClick={() => setShowHotkeysPopover(!showHotkeysPopover)}
            >
              Press ESC for keyboard shortcuts
            </button>
          </div>
        )}
      </div>
    </main>
  );
}
